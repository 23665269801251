.employers-page-title {
    font-size : 30px;
    margin-bottom: 3rem;
}

.benefits {
    margin:20px;
}

.employers-content-img {
    border-radius: 5%;
    box-shadow: 5px 10px 25px 0px rgba(0, 0, 0, 0.2);
    max-width: 300px;
}

.benefits img {
    display: block;
    margin: auto;
}

.benefits {
    margin: 20px;
}
.benefits h3 {
    margin-top: 60px;
}
.benefits img {
    display: block;
    margin: auto;
}

.convinced-yet {
    width: 90%;
    height: 300px;
    margin: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 0px;
    border-radius: 8px;
    box-shadow: 0px 10px 30px rgba(66, 69, 97, 0.167799);
    text-align: center;
    overflow: hidden;
    transition: 0.2s;
}
.convinced-yet .row {
    height: 110px;
    width: 100%;
    margin: 0px;
    padding: 25px;
    transition: 0.2s;
}
.convinced-yet .row p {
    margin: 20px;
}

.convinced-yet .contact-us-box {
    position: relative;
    margin-top: 40px;
    width: 100%;
    height: 100%;
    background: #FFC539;
}

.convinced-yet .contact-us-box .white-black-button {
    display: inline-block;
    padding-left: 50px;
    padding-right: 50px;
    margin: auto;
    margin-top: 50px;
}

.white-black-button
{
    background-color: white;
    color: black;
    font-size: 18px;
    border-radius: 250px;
    text-decoration: none;
    font-weight: bold;
    padding: 14px;
    padding-right: 14px;
    padding-left: 14px;
    padding-left: 24px;
    padding-right: 24px;
    cursor: pointer;
}

.tabs {
    box-shadow: 0px 10px 30px rgb(66 69 97 / 17%);
    border-radius: 8px;
}

.youre-next-tab {
    padding: 100px;
    padding-top: 60px;
    padding-bottom: 60px;
    height: auto;
    overflow: hidden;
    margin-bottom: 100px;
}
.youre-next-tab h3 {
    text-align: center;
    padding-bottom: 20px;
}

.youre-next-tab .image-container {
    margin-top: 40px;
    margin-bottom: 40px;
    overflow: hidden;
    padding: 15px;
}

.youre-next-tab .image-container .image {
    padding-left: 15px;
    padding-right: 15px;
}

.youre-next-tab .image-container img {
    width: 100%;
    margin: auto;
}

.youre-next-tab .company-package {
    max-width: 950px;
    margin: 0 auto;
    margin-top: 80px;
    height: auto;
}

.youre-next-tab .company-package h3 {
    margin-top: 0px;
    margin-bottom: 21px;
    padding: 0px;
    text-align: left;
}

.youre-next-tab .company-package p {
    color: #7B8C9F;
    margin-bottom: 26px;
    line-height: 28px;
}

.nav-tabs .nav-link {
    border: none;
    color: black;
}

.nav-tabs .nav-link.active {
    border: none;
    border-bottom: 4px solid #298B63;
}

.nav-tabs .nav-link {
    font-size: 20px;
    color: #4A596B;
    font-weight: bold;
    padding-top: 32px;
    padding-bottom: 32px;
}

@media screen and (max-width: 1200px) {
    .convinced-yet {
        height: 330px;
        width: 95%;
    }

    .convinced-yet .row {
        height: 135px;
        width: 100%;
        margin: 0px;
        padding: 25px;
    }
}

