h1 {
    color: #313B47;
    font-size: 4.0625em;
    font-weight: bold;
    font-family: "HKGrotesk";
}

p {
	color: #4d4d4d;
	font-family: 'Helvetica_Neue', Helvetica, sans-serif;
	font-size: 1.125em;
}

.goodbye {
	text-align: center;
}

.about-orange {
	width: 100%;
	text-align: center;
	padding-top: 1.875em;
	padding-bottom: 5em;
	padding-left: 15%;
	padding-right: 15%;
	background: #ffd98d;
}

.about-orange h1 {
	font-style: normal;
	font-weight: 600;
	font-size: 3em;
	line-height: 1.5em;
	text-align: center;
}

.about-orange h3 {
	font-style: normal;
	font-weight: 600;
	font-size: 1.5em;
	line-height: 1.5em;
	text-align: center;
}

.about-orange p {
	font-style: normal;
	font-weight: normal;
	font-size: 1.125em;
	line-height: 1.5em;
	text-align: center;
}

.about-orange img {
	max-width: 100%;
	height: auto;
}

.paddedColumn {
	padding-left: 0%;
	padding-right: 0%;
}

.about-header {
	padding-bottom: 5em;
	color: black;
	text-align: center;
}

.about-header .about-subtext {
	padding: 15%;
	padding-top: 0em;
	padding-bottom: 0em;
}

.about-header .pg-title {
	font-style: normal;
	font-weight: 600;
	font-size: 3em;
	line-height: 4.5em;
	text-align: center;
	padding: 0;
}

.wrapper {
	display: flex;
	flex-wrap: wrap;
	padding-left: 15%;
	padding-right: 15%;
}
.col {
	flex-grow: 1;
	width: 33%;
}

.col div {
	margin-left: 8.33333333%;
	width: 83.33333333%;
}

.our-vision {
	margin-bottom: 4em;
}

.our-strategy {
	margin-bottom: 1.2em;
}
