
#cf-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}

#cf-scroll::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

#cf-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.career-fair-content {
  margin: 50px;
}

.partners-content {
  margin: 50px;
  text-align: center;
}


.career-fair-content h3 {
  font-size: 36px;
}
.career-fair-content .details-box {
  width: 100%;
  margin: auto;
  padding: 10px;
  border-radius: 12px;
  min-height: 300px;
  box-shadow: 0px 10px 30px rgba(66, 69, 97, 0.167799);
  -webkit-box-shadow: 0px 10px 30px rgba(66, 69, 97, 0.167799);
  -moz-box-shadow: 0px 10px 30px rgba(66, 69, 97, 0.167799);
}
.career-fair-kickoff .details-box {
  margin: 0;
      margin-bottom: 0px;
  margin-bottom: 32px;
}
@media screen and (max-width: 453px) {
  .career-fair-content .details-box {
    margin: 50px -20px;
  }
}
.career-fair-content .details-box .details-text {
  justify-content: center;
  padding: 20px;
  font-size: 18px;
  font-family: 'HK';
}
.career-fair-content .details-buttons {
  margin-top: 40px;
  margin-bottom: 35px;
  text-align: center;
}

@media screen and (max-width: 453px) {
  .career-fair-content .details-box .details-buttons {
    margin-top: 30px;
  }
}

.career-fair-content .about-cf-box {
  width: 80%;
  margin: auto;
}

.career-fair-content .see-all-companies {
  position: relative;
  top: 10px;
}
@media screen and (max-width: 426px) {
  .career-fair-content .see-all-companies {
    top: 36px;
  }
}
.career-fair-content .see-all-companies {
  margin-top: 32px;
}

.see-all-companies{
  text-align: center
}

.career-fair-content .info-row {
  margin-top: 70px;
}
.career-fair-content .info-row h3 {
  font-size: 36px;
}
.career-fair-content .info-row .cf-recruit img {
  margin-left: 50px;
  max-width: 360px;
}
.career-fair-content .info-row img {
  max-width: 100%;
  max-height: 100%;
}
.career-fair-content .info-row span {
  font-weight: bold;
}
.career-fair-content .info-row .new-list li {
  margin-left: -20px;
}

.hubilo {
  background-color: rgba(163, 229, 187, 0.75);
  margin: 0 auto;
  max-width: 100%;
  padding-bottom: 50px;
  text-align: center;
}

.hubilo-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 17px;
  border: none;
}

.career-fair-content .dl-box .hubilo-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 62.5%; /* 8:5 Aspect Ratio */
  background: white;
  border-radius: 12px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}

.career-fair-content .dl-text {
  padding-bottom: 22px;
}

.career-fair-content .dl-box {
  display: contents;
  max-width: 100%;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0px 5px 20px 1px rgba(68, 68, 68, 0.3);
  -webkit-box-shadow: 0px 5px 20px 1px rgba(68, 68, 68, 0.3);
  -moz-box-shadow: 0px 5px 20px 1px rgba(68, 68, 68, 0.3);
  background: white;
}
@media screen and (max-width: 400px) {
  .career-fair-content .dl-box {
    max-width: 200%;
    margin-left: -50px;
    margin-right: -50px;
  }
}
.career-fair-content .dl-box h3 {
  color: #00d2cf;
}
.career-fair-content .dl-box .dl-text {
  padding: 0 150px;
  margin: 50px 0px;
}
@media screen and (max-width: 991px) {
  .career-fair-content .dl-box .dl-text {
    padding: 10px;
  }
}
.career-fair-content .dl-box .color-box {
  padding: 0;
  margin: 0;
}
.career-fair-content .dl-box .dl-guide {
  min-height: 375px !important;
  padding: 50px;
  height: 100%;
}
@media screen and (max-width: 991px) {
  .career-fair-content .dl-box .dl-guide {
    padding: 50px 10px;
  }
}
.career-fair-content .dl-box .dl-guide h3 {
  color: white;
}
.career-fair-content .dl-box .dl-guide p {
  color: white;
}
.career-fair-content .dl-box .dl-guide > * {
  margin: 40px 0;
}
.career-fair-content .dl-box > * {
  padding-left: 0;
  padding-right: 0;
}
.career-fair-content .info-buttons {
  margin-top: 40px;
  margin-bottom: 40px;
}
.career-fair-content .info-buttons div {
  display: inline;
  margin-right: 10px;
}
@media screen and (max-width: 453px) {
  .career-fair-content .info-buttons div {
    margin-right: 5px;
  }
}
@media screen and (max-width: 350px) {
  .career-fair-content .info-buttons div {
    display: block;
    margin-top: 20px;
  }
}

.career-fair-kickoff h1, .career-fair-kickoff h2, .career-fair-kickoff h3, .career-fair-kickoff p, .career-fair-kickoff a {
  font-family: "HKGrotesk";
}

.career-fair-kickoff p i{
    color: #576679;
}

.career-fair-kickoff .keynote-speaker {
  display: block;
  box-shadow: 0px 10px 30px rgba(66, 69, 97, 0.167799);
  border-radius: 8px;
}
.career-fair-kickoff .keynote-speaker .image-wrapper img {
  width: 100%;
  max-width: 298px;
  padding: 32px;
}

.career-fair-kickoff .about-cf-box p:last-of-type {
  margin-bottom: 32px;
}

.career-fair-kickoff .keynote-speaker .image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 64px;
  padding-right: 64px;
  padding-bottom: 64px;
  min-height: 380px;
}
.career-fair-kickoff .highlight {
  color: #00D2CF;
  font-weight: bold;
}

.career-fair-content .info-row img {
  max-width: 100%;
  max-height: 100%;
}

.career-fair-kickoff .keynote-speaker .row div {
  padding: 0;
}

.career-fair-kickoff .keynote-speaker .description {
  text-align: left;
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 63px;
  margin-bottom: 63px;
  display: inline-block;
}
.career-fair-kickoff .details-box i {
  font-size: 18px;
}

.info-row .link-button {
  margin-right: 20px;
}

.career-fair-content .student-guide {
  width: 90%;
}

.link-button-clear:visited {
  text-decoration: none;
  color: #00D2CF;
}
.link-button-clear {
  background-color: white;
  border: 1px solid #00D2CF;
  color: #00D2CF;
  font-size: 18px;
  border-radius: 250px;
  text-decoration: none;
  padding: 14px;
      padding-right: 14px;
      padding-left: 14px;
  padding-left: 24px;
  padding-right: 24px;
  font-weight: bold;
}

.banner {
  width: 100%;
}


