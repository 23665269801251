body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Helvetica_Neue';
  font-weight: normal;
  font-style: normal;
  src: url("assets/fonts/Helvetica_Neue/regular/Helvetica_Neue.eot") format('eot'),
    url("assets/fonts/Helvetica_Neue/regular/Helvetica_Neue.woff") format('woff'),
    url("assets/fonts/Helvetica_Neue/regular/Helvetica_Neue.ttf") format('truetype'),
    url("assets/fonts/Helvetica_Neue/regular/Helvetica_Neue.svg#Helvetica_Neue") format('svg');

  }

@font-face {
  font-family: 'Helvetica_Neue';
  font-weight: bold;
  font-style: normal;
  src: url("assets/fonts/Helvetica_Neue/bold/Helvetica_Neue.eot") format('eot'),
    url("assets/fonts/Helvetica_Neue/bold/Helvetica_Neue.woff") format('woff'),
    url("assets/fonts/Helvetica_Neue/bold/Helvetica_Neue.ttf") format('truetype'),
    url("assets/fonts/Helvetica_Neue/bold/Helvetica_Neue.svg#Helvetica_Neue") format('svg');
}

@font-face {
  font-family: 'HKGrotesk';
  font-weight: normal;
  font-style: normal;
  src: url("assets/fonts/HKGrotesk/regular/HKGrotesk.eot") format('eot'),
    url("assets/fonts/HKGrotesk/regular/HKGrotesk.woff") format('woff'),
    url("assets/fonts/HKGrotesk/regular/HKGrotesk.ttf") format('truetype');
}

@font-face {
  font-family: 'HKGrotesk';
  font-weight: bold;
  font-style: normal;
  src: url("assets/fonts/HKGrotesk/bold/HKGrotesk.eot") format('eot'),
    url("assets/fonts/HKGrotesk/bold/HKGrotesk.woff") format('woff'),
    url("assets/fonts/HKGrotesk/bold/HKGrotesk.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  src: url("assets/fonts/Poppins/Poppins-SemiBold.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  src: url("assets/fonts/Poppins/Poppins-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  src: url("assets/fonts/Poppins/Poppins-Light.ttf") format('truetype');
}


@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: normal;
  src: url("assets/fonts/Poppins/Poppins-ExtraLight.ttf") format('truetype');
}


* {
  font-family: inherit;
  font-size: inherit;
}

h1 {
  color: #313B47;
  font-size: 65px;
  font-weight: bold;
}

h2 {
  color: #424731;
  font-weight: bold;
  font-size: 38px;
  line-height: 1.5;
  border-bottom: 1px solid rgba(77, 77, 77, 0.5);
  margin-bottom: 20px;
}

h3 {
  color: #4d4d4d;
  font-size: 24px;
  font-weight: bold;
}


h4 {
  color: #4d4d4d;
  font-size: 20px;
  font-weight: bold;
}

h5 {
  display: block;
  font-weight: 600;
  font-size: 18px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

p {
  color: #4d4d4d;
  font-size: 18x;
  margin: 0 0 10px;
}

h1, h2, h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 1.1;
}

h1, h2, h3, h4, p {
  font-family: "Helvetica_Neue", Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*use this to style a Col that stores a Card; this puts the cards in a grid*/
.card-column {
  height: 285px;
  width: 280px;
  word-wrap: break-word;
  margin: 13px;
  background: white;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 25%);
  overflow: hidden;
  text-align: left;
}

/*to use stretched link for a card*/
.card-item {
  height: 100%;
  width: 100%;
  mask-image: linear-gradient(180deg, #000 85%, transparent);
  overflow-y: scroll; /* Add the ability to scroll */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.card-item::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.card-item {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.card-column h4 {
  font-family: "HKGrotesk", Helvetica, sans-serif;
  font-weight: bold;
  display: block;
  font-size: 24px;
  color: #156177;
  padding-left: 36px;
  padding-right: 36px;
  margin-block-start: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.card-column h5 {
  font-family: "HKGrotesk", Helvetica, sans-serif;
  color: #50AFB5;
  padding-left: 36px;
}

.card-column p {
  font-size: 18px;
  color: #5B5B5C;
  padding-left: 36px;
  padding-bottom: 39px;
  padding-right: 36px;
}

.text-center {
  text-align: center;
}

@media screen and (max-width: 400px) {
  .card-column {
    width: '70vw'
  }
}