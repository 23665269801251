.standard-partners .cards-header  h3 {
  font-family: "HKGrotesk", Helvetica, sans-serif;
  text-align:center;
  color: #5B5B5C;
  padding-top: 1.75em;
}

.standard-partners .cards-container {
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  position: relative;
  max-width: 1300px;
}

.standard-partners .card-in-scroll {
  display: inline-block;
  border: none;
  white-space: pre-wrap;
  padding-left: 25px;
  padding-right: 25px;
  align-self:center
}

@media only screen and (max-width: 600px) {
	.standard-partners .card-in-scroll {
		display: inline-block;
		border: none;
		white-space: pre-wrap;
		padding-left: 5px;
		padding-right: 20px;
		align-self:center
	}

}

.standard-partners .box-container {
  padding-bottom: 45px;
}

.standard-partners .horizontal-slider {
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* This is for chrome/opera/safari */
.standard-partners .horizontal-slider::-webkit-scrollbar {
  display: none;
}

.standard-partners .slider-arrow {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 1%;
  user-select: none;
  padding: 0;
  border: none;
  background: none;
  margin: 40px;
}
