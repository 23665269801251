.logo-pic {
    width:150px;
}

h1 {
    color: #313B47;
    font-size: 3em;
    font-weight: bold;
    font-family: "HKGrotesk";
}

h3 {
    color: #313B47;
    font-size: 2.250em;
    font-weight: bold;
    font-family: "HKGrotesk";
}

p {
	color: #4d4d4d;
	font-family: 'Helvetica_Neue', Helvetica, sans-serif;
	font-size: 1.125em;
}

.partners-header {
	padding-bottom: 5em;
	color: black;
	text-align: center;
}

.partners-subtext {
	padding: 15%;
	padding-top: 0em;
	padding-bottom: 0em;
}

.partners-header .pg-title {
	font-style: normal;
	font-weight: 600;
	font-size: 3em;
	line-height: 4.5em;
	text-align: center;
	padding: 0;
}

.partners-list {
    text-align: center;
    padding-top: 1em;
	padding-bottom: 3em;
}

.gold-plus-partners {
    background: linear-gradient(180deg, rgba(247, 227, 49, 0.375) 0%, rgba(247, 181, 49, 0) 100%);
}

.gold-plus-partners h3 {
    color: #e69d15;
    filter: drop-shadow(0px 4px 4px rgba(247, 181, 49, 0.5));
    margin-bottom: 1.125em;
}

.gold-partners {
    background: linear-gradient(180deg, rgba(247, 181, 49, 0.375) 0%, rgba(247, 181, 49, 0) 100%);
}

.gold-partners h3 {
    color: #E58123;
    filter: drop-shadow(0px 4px 4px rgba(247, 181, 49, 0.5));
    margin-bottom: 1.125em;
}

.silver-partners {
    background: linear-gradient(180deg, rgba(91, 91, 92, 0.25) 0%, rgba(91, 91, 92, 0) 100%);
    padding-bottom: 0em;
}

.silver-partners h3 {
    color: #5B5B5C;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin-bottom: 1.125em;
}

.standard-partners-background {
    background: linear-gradient(180deg, rgba(205, 127, 50, 0.375) 0%, rgba(247, 181, 49, 0) 100%);
}

.event-partners {
    padding-bottom: 0em;
    background: linear-gradient(180deg, rgba(216, 216, 216, 0.375) 0%, rgba(247, 181, 49, 0) 100%);
}

.event-partners h3 {
    color: #5B5B5C;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin-bottom: 1.125em;
}

.goodbye {
	text-align: center;
}

.goodbye .learn-more-button {
    margin-top: 3em;
}

.goodbye img {
	margin-top: 0.5em;
	max-width: 25em;
	height: auto;
}

@media only screen and (max-width: 600px) {

	.goodbye img {
		width: 100%
	}
}

a {
    cursor: pointer;
}

img.cf-img {
  transition: transform .2s;
}

img.cf-img:hover {
  transform: scale(1.2);
}