
.carousel-slider{
    overflow: visible!important;
}

ul.control-dots{
    margin-bottom: -50px!important;
}

@media only screen and (min-width:540px) and (max-width:750px) {

    .button-inner-img{
        display: none!important;
    }

    .carousel-buttons{
        background-color: rgb(27, 97, 119)!important;
        height: 10px!important;
        width: 10px;
        margin: 10px;
        border-radius: 5px;
        margin-bottom: 40px;
    }
 }

 @media only screen and (min-width:360px) and (max-width:540px) {

    .button-inner-img{
        display: none!important;
    }

    .carousel-buttons{
        background-color:rgb(27, 97, 119)!important;
        height: 7px!important;
        width: 7px;
        margin: 7px;
        border-radius: 7px;
        margin-bottom: 35px;
    }

 }

 @media only screen and (min-width:0px) and (max-width:360px) {

    .button-inner-img{
        display: none!important;
    }

    .carousel-buttons{
        background-color: rgb(27, 97, 119)!important;
        height: 5px!important;
        width: 5px;
        margin: 5px;
        border-radius: 5px;
        margin-bottom: 45px;
    }

 }