/*Featured Card Sections*/
.cards-header {
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 64px;
    padding-left: 0%;
    padding-right: 0%;
}

.cards-header  h3 {
    font-family: "HKGrotesk", Helvetica, sans-serif;
    text-align:left;
    color: black;
}

.cards-header  p {
    text-align:left;
}

.cards-container {
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    position: relative;
    max-width: 1300px;
}

.view-all-button {
    background-color: #156177;
    color: white;
    font-size: 20px;
    border-radius: 250px;
    text-decoration: none;
    padding: 14px;
    padding-right: 14px;
    padding-left: 14px;
    padding-left: 24px;
    padding-right: 24px;
    font-family: HKGrotesk;
    float: right;
    margin-top: 28px;
    margin-bottom: 15px;
}

.view-all-button:hover {
    text-decoration: none;
    font-weight: 500;
    color: white;
}

.card-in-scroll {
    display: inline-block;
    border: none;
    word-wrap: break-word;
    white-space: pre-wrap;
    flex-basis: calc(100% / 3);
    flex-grow: 0;
    flex-shrink: 0;
    transition: ease-in-out 0.25s;
}

.card-in-scroll:hover {
    transform: scale(1.02);
}

.box-container {
    padding-bottom: 45px;
}

.horizontal-slider {
    display: block;
    width: 100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    margin-top: 16px;
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

/* This is for chrome/opera/safari */
.horizontal-slider::-webkit-scrollbar {
    display: none;
}


.slider-container {
    display: block;
    white-space: nowrap;
}

@media only screen and (max-width: 1200px) {
    .card-in-scroll {
        flex-basis: 50%;
    }
    .view-all-button {
        float: left;
    }
}


@media only screen and (max-width: 992px) {
    .card-in-scroll {
        flex-basis: 100%;
    }
}

