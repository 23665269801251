.resources {
    width: 100%;
    padding: 2% 10% 1% 10%;
}

/* Top Text Section */

.resources-header h1 {
    padding: 0 0 10px 0;
    text-align: left;
}

.resource-dropdown {
	margin: 0px;
	padding: 0px;
	text-decoration: underline;
}

.resource-type-choice {
	font-weight: bold;
	font-size: 20px;
}

.top-header-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.resource-dropdown #dropdown-text {
	padding: 0px;
}
/* Filter Section */

.filter {
    color: white;
    border: none;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 25px;
    margin: 1% 1% 1% 0;
}

.multiply {
    transform: rotate(45deg);
    padding-bottom: 5px;
}
#career-fair {
    background-color: rgb(238, 110, 91);
}

#career-fair:hover {
    background-color: rgb(184, 57, 38);
}

#hiring-process {
    background-color: rgb(238, 183, 79);
}

#hiring-process:hover {
    background-color: rgb(214, 142, 8);
}

#personal-discovery {
    background-color: rgb(62, 158, 163);
}

#personal-discovery:hover {
    background-color: rgb(38, 115, 119);
}

#work-advice {
    background-color: rgb(72, 137, 102);
}

#work-advice:hover {
    background-color: rgb(63, 116, 87);
}

#industry-knowledge {
    background-color: rgb(45, 96, 117);
}

#industry-knowledge:hover {
    background-color: rgb(25, 63, 80);
}

svg {
    padding-left: 5%;
}

.clear-filter {
    border: none;
    background-color: rgb(0, 0, 0, 0);
    font-size: 20px;
}

.right-space {
    padding-right: 5px
}



.sort {
    color: white;
    border: none;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 25px;
    margin: 1% 1% 1% 0;
}

