.home {
    text-align: center;
}

h1 {
    font-size: 50px;
    color: #313B47;
}

h3 {
    font-size: 30px;
    color: #313B47;
}

p {
    font-size: 20px;
    line-height: 1.42857143;
}

/* Hero Section */
.hero {
    position: relative;
    padding-top: 50px;
    padding-bottom: 100px;
}

.hero-text {
    text-align: left;
    max-width: 710px;
    padding-left: 10%;
}

.hero h1 {
    padding-top: 0px;
    text-align: left;
    padding-left: 14px;
    max-width: 505px; 
    width: 90%;

}

.hero h3 {
    padding-left: 16px;
    padding-right: 16px;
    max-width: 505px;
    width: 90%;
    color: #5B5B5C;
    font-size: 30px;
}

.subtext {
    padding-left: 16px;
    padding-right: 16px;
    max-width: 600px;
    width: 90%;
    margin-bottom: 40px;
    color: #5B5B5C; 
    font-size: 20px;
}

.illustrations {
    z-index: -100;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background-size: 100%; 
}

#home-ill{
    float:right;
    padding-top:50px;
    padding-right:25px;
    max-width:100%;
    max-height:100%;
  }

@media (max-width: 1300px) {
    #home-ill {
        display:none;
    }
}

/* Testimonials Section */
.yellow-background {
    display:flex; 
    background-color:#FFE7B8;
    padding: 5% 0;
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
}

.yellow-background h1 {
    font-size: 40px;
    padding: 0px;
}

.yellow-background h3 {
    font-size: 20px;
    padding: 0 10% 0 10%;
}

.yellow-background p {
    padding: 0 10% 0 10%;
    color: rgb(77,77,77); 
    font-size: 20px;
}

.drop-shadow-box {
    background-color: white;
    align-items: center;
    padding: 3% 0 3% 0;
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgb(182, 167, 136);
}

@media screen and (min-width: 768px) {
    .drop-shadow-box {
        width: 52%;
    }
}

@media screen and (max-width: 767px) {
    .drop-shadow-box {
        width: 82%;
    }
}

.drop-shadow-box p {
    padding: 0 7% 0 7%;
    color: rgb(77,77,77); 
    font-size: 20px;
}

.drop-shadow-box h3 {
    text-align: center;
    color: black;
    font-size: 20px;
    font-weight: bold;
}

/* Recruiter / Student Section */
.recruiter-student {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 3% 0 2% 0;
}

.recruiter-student h1 {
    text-align: left;
    font-size: 30px;
    padding: 0 0 0 4%;
}

.recruiter-student p {
    text-align: left;
    font-size: 20px;
    padding: 1% 0 4% 4%;
}

.recruiter-box {
    width: 50%;
    border-right: 1px solid black;
    text-align: left;
    flex: 1 1 430px;
    padding-left: 5%;
    padding-bottom: 5%;
}

.inside-container1 {
    width: 430px;
    float: right;
    padding-right: 6%;
    height: 230px;
}

.student-box {
    width: 50%;
    text-align: left;
    flex: 1 1 430px;
    padding-right: 5%;
    padding-bottom: 5%;
}

.inside-container2 {
    width: 450px;
    float: left;
    padding-left: 6%;
    height: 230px;
}

@media screen and (max-width: 1030px) {
    .recruiter-box {
      width: 100%;
      height: 50%;
      border-color: white;
    }

    .student-box {
        width: 100%;
        height: 50%;
    }

    .inside-container1 {
        width: 90%;
        float: left;
        padding-left: 1%;
    }

    .inside-container2 {
        width: 90%;
        float: left;
    }
}

@media screen and (max-width: 490px) {
    .recruiter-student h1 {
        line-height: 100%;
    }

    .recruiter-student p {
        margin-top: 5%;
    }
    
    .recruiter-box {
        margin-top: 5%;
        margin-bottom: 5%;
    }
  
    .student-box {
        margin-top: 10%;
        margin-bottom: 10%;
    }
}

.register-cf-container {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    padding-left: 80px;
    padding-right: 80px;
    width: 80%;
}
