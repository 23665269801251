.nav-header {
	color: white;
	position: relative;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.175);
	z-index: 100;
	padding-right: 1em;
}

.nav-header .team-icon {
	position: relative;
	top: -15px;
}

.nav-header .sub-menu {
	padding: 5px 0;
}

.nav-header .sub-menu a {
	color: white;
	font-size: 20px;
}

.nav-header .sub-menu a.active {
	text-decoration: underline;
}

.nav-bar a {
	text-decoration: none;
	padding: 10px 15px;
}

li {
	padding: 1rem 0.7em;
}

.resources>.navbar-nav #resources {
	color: #399996;
	text-decoration: underline;
}

.short-logo {
	height: 30px;
	width: 30px;
	position: relative;
	top: -5px;
}

.navbar {
	background-color: #ffffff;
	border: 0;
}

.navbar-nav>li>.dropdown-menu {
	box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.175);
	border-width: 0px;
}

.navbar i {
	font-size: 18px;
	margin-left: 3px;
}

.navbar.navbar-default {
	margin-bottom: 0;
}

/* special style for the link to the employers page */
#employers-title {
	color: #006379;
	width: auto;
	height: auto;
	padding: 12px 26px;
	border: solid #006379 2px;
	border-radius: 250px;
	background-color: white;
}

.navbar-on {
	/* If you change the color here, it changes the color of the title when u put cursor on subtitles */
	color: #006379 !important;
	background-color: #ffffff;
}

.navbar .navbar-nav .drophold {
	cursor: pointer;
}

.navbar .navbar-nav .fa {
	font-size: 16px;
	opacity: 0.5;
}

.navbar .navbar-nav a {
	font-family: 'HKGrotesk';
	font-weight: bold;
	font-size: 20px;
}

.navbar .navbar-nav>li>a {
	color: #4d4d4d;
	background-color: #ffffff;
	line-height: 18px;
	padding: 12px 26px;

	/* Override to remove #FFFFFF gap */
}

.navbar .navbar-nav>li>a.active {
	color: #006379;
	border-radius: 250px;
}

@media only screen and (min-width: 1200px) {
	.navbar .navbar-nav>li>a {
		height: 50px;
	}
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
	.navbar .navbar-nav>li>a {
		height: 50px;
	}
}

@media only screen and (max-width: 990px) and (min-width: 768px) {
	.navbar .navbar-nav>li>a {
		height: 50px;
	}
}

.navbar .navbar-nav>li>a:hover,
.navbar .navbar-nav>li>a:focus {
	color: #006379;
	background-color: #FAE5B4 !important;
	border-radius: 250px;
}

.navbar .navbar-nav>li>a:focus {
	color: #006379;
	background-color: #FAE5B4 !important;
	border-radius: 250px;
}

.navbar .navbar-nav>li>.dropdown-menu>li>a {
	padding: 3px 8px;
	color: #4d4d4d;
}

.navbar .navbar-nav>li>.dropdown-menu>li>a:hover {
	color: #006379;
	background-color: #FAE5B4;
}

.navbar .navbar-nav>li>.dropdown-menu>li>a:focus {
	color: #006379;
	background-color: #FAE5B4 !important;
	text-decoration: underline;
	border-radius: 250px;
}

.navbar .navbar-nav>li:hover>ul {
	display: block;
	background-color: #FAE5B4
}

.navbar-collapse .dropdown {
	padding: 0.3em 0.5em;
}

#dropdown-text {
	color: #4d4d4d;
}

.dropdown-item {
	color: #4d4d4d;
	padding: 0.5rem 0.5em;
}

.dropdown-item:hover {
	color: #006379;
	background-color: #FAE5B4;
}

.dropdown-item:focus {
	color: #006379;
	background-color: #FAE5B4 !important;
	text-decoration: underline;
}

.dropdown-menu {
	width: max-content;
	margin: auto !important;
}

/**
   * Hamburger Bars on Mobile
   */
.navbar-toggler {
	outline: none;
	box-shadow: none;
}

.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-collapse.in {
	overflow: visible;
}

.navbar .navbar-toggle .icon-bar {
	background-color: #ffffff;
}

.navbar-collapse {
	border-top: 1px solid transparent;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
	text-align: center;
}

.navbar-image-container {
	padding-top: 0.55rem;
	padding-right: 4rem;
	padding-bottom: 0.55rem;
	padding-left: 1.3rem;
}

@media screen and (max-width: 1050px) {
	.navbar-image-container {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.navbar-image-container .navbar-image {
	height: auto;
	width: auto;
	max-height: 40px;
}