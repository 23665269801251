#footer {
  min-height: 6.25em;
  background-image: linear-gradient(white, #f0f0f7);
  position: relative;
}

.footer-text {
  padding-top: 1.25em;
}

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.fa-stack-1x, .fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.home-pg .social-media {
  margin-top: 0.625em;
  text-align: center; }

.home-pg .social-media .icon {
display: inline-block; }

.home-pg .social-media button {
  color: #05CFF1;
  border: 0;
  background-color: transparent; }



