.map {
    margin: 0 !important;
    width: 100% !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 80vh;
}

.mapContainer {
    box-sizing: border-box;
    border: solid 2px #5c4435;
    background-color: rgb(238,243,249);
    height: 100%;
}

.maps * {
    margin: 0;
    padding: 0;
}

.map > div,
.map > div *,
.col2 *,
.companies * {
    margin: 0 !important;
}

.maps {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.col1 {
    width: 60vw !important;
}

.col2 {
    height: 90vh !important;
    overflow: scroll;
}

#floorplan {
    top: 0px;
    left: 0px;
}

/* Top Text Section */

.maps-header {
    margin-left: 2%;
}

.maps-header h1 {
    padding: 0 0 10px 0;
    text-align: left;
}

.booth-modal {
    z-index: 997;
}

.cf-map-img {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 900px) {
    .cf-map-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .maps-header h1, h4 {
        text-align: center;
    }

}

.filter-header-bg {
    background: linear-gradient(#FDFCFC 6.42%, #F2F2FA 89.32%);
    border-top-left-radius: 15px;
}

@media only screen and (max-width: 990px) {
    .search-filter {
        width: 100%;
    }

    .map {
        height: 70vh;
    }
}

@media only screen and (max-width: 605px) {
    .years-filter {
        padding-right: 20px;
    }
    
}

.filter-header-bg input {
    color: #43494C;
    background: #FFFFFF;
    box-shadow: inset 0px 2px 5px rgb(131 131 131 / 50%);
    border-radius: 4px;
    border: 1px solid #CDCDE4;
    height: 53px;
    width: 100%;
    padding-left: 20px;
}

.filter-header-bg select {
    appearance: none;
    border: 0px;
    background:rgba(0, 0, 0, 0);
    font-size: 24px;
    font-weight: bold;
    color: #444444;
    max-width: 100%;
    text-align: center;
    text-align-last: center;
    vertical-align: middle;
}

.filter-wrapper {
    padding: 2rem;
    width: 100%;
}

.search-filter {
    padding-right: 20px;
    min-width: 350px;
}

.years-filter .chip {
    background: #50B1B6 !important;
}

.disciplines-filter .chip {
    background: #6DC38E !important; 
}

.disciplines-filter, .years-filter {
    min-width: 270px;
}

.disciplines-filter {
    padding-right: 20px;
}

.scrollTopButton {
    position: sticky;
    top: 0px;
    left: 80%;
    align-self: flex-end;
    border: none;
    border-radius: 15px;
    background-color: #50B1B6;
    color: white;
    font-size: 1.2em;
    text-decoration: none;
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 10;
}


.multiSelectContainer input {
    height: 38px
}

@media only screen and (max-width: 990px) {
    .filter-wrapper {
        padding: 1rem;
    }
}