.header-bg {
    background: linear-gradient(#FDFCFC 6.42%, #F2F2FA 89.32%);
    z-index: -1;
}

.header-bg input {
    color: #43494C;
    background: #FFFFFF;
    box-shadow: inset 0px 2px 5px rgb(131 131 131 / 50%);
    border-radius: 4px;
    border: 1px solid #CDCDE4;
    height: 53px;
    width: 100%;
    padding-left: 20px;
}

.header-bg select {
    appearance: none;
    border: 0px;
    background:rgba(0, 0, 0, 0);
    font-size: 24px;
    font-weight: bold;
    color: #444444;
    max-width: 100%;
    text-align: center;
    text-align-last: center;
    vertical-align: middle;
}

.companies-header-container {
	padding-bottom: 50px;
}

.company-list-box {
    background-color:#FFFFFF;
    margin-bottom: 50px;
    border-radius: 12px;
    min-height: 300px;
    box-shadow: 0px 10px 30px rgba(66, 69, 97, 0.167799);
    -webkit-box-shadow: 0px 10px 30px rgba(66, 69, 97, 0.167799);
    -moz-box-shadow: 0px 10px 30px rgba(66, 69, 97, 0.167799);
}

div.target-info {
    margin: 0px;
}

.company-row {
    padding: 20px;
    border-bottom: 1px solid #EEECF7;
}

@media only screen and (max-width: 600px) {
  .company-row-logo {
		padding: 0px 25px 25px 25px;
  }

}

.company-row a {
    color: #00AAC9;
    font-weight: bold;
    font-size: 36px;
    padding-top: 0px;
    padding-bottom: 20px;
    line-height: 36px;
}

.company-row .highlight {
    color: #00AAC9;
    font-weight: bold;
}

.company-row .highlight-gray {
    color: #8A8C8D;
    font-weight: bold;
}

.company-row p {
    color: #7B8C9F;
    font-size: 16px;
    margin-top: 25px;
}

.company-row .minimized-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.company-row .expand-button {
    cursor: pointer;
    color: #2e7ea7;
    margin: 0px;
}

h1 {
    padding-top: 50px;
    padding-bottom: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    margin: 0px;
}

h4 {
    padding-bottom: 15px;
    text-align: center;
}

.company-list-box h5 {
    color: #979797;
    font-size: 24px;
    padding: 21px 0px;
    text-align: center;
    border-bottom: 1px solid #CDCDE4;
}

.target-info {
    font-size: 1.5vh;
}

.target-info b {
    color: #43494C;
}

.company-row img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.years-filter .chip {
    background: #50B1B6 !important;
}

.disciplines-filter .chip {
    background: #6DC38E !important; 
}

.disciplines-filter, .years-filter {
    min-width: 270px;
}

.disciplines-filter {
    padding-right: 20px;
}