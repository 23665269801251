.container{
	display:block;
	background-color: transparent;
	margin-bottom: 50px;
}

.profile-pic {
	-webkit-border-radius: 2.5%;
	border-radius: 2.5%;
	height:200px;
	width:180px;
	object-fit: cover;
}
.about-team-desc {
	position: absolute;
	bottom: 10px;
	left: 0;
	right: 0;
	background-color: white;
	opacity: 0.6;
	overflow: hidden;
	width: 100%;
	height: 0;
	transition: 0.5s ease;
	pointer-events: none;
}

.profile {
	position:relative;
	padding:10px;
}

a:hover ~ .about-team-desc{
	height: 35%;
}

.about-team-text {
	height: inherit;
	width: inherit;
	color: black;
	font-size: 14px;
	position: absolute;
	top: 20%;
	left: 20%;
	padding: 5px 25px;
	-webkit-transform: translate(-20%, -20%);
	    transform: translate(-20%, -20%);
	-ms-transform: translate(-20%, -20%);
	transform: translate(-20%, -20%);
	text-align: center;
}

.team-tab-content {
    padding-left: 64px;
    padding-right:64px;
    margin: 8px;
	padding-bottom: 20px;
}

.team-description{
    margin-bottom:30px;
    display: inline-block;
}

.team-description h2{
	font-family: "HKGrotesk";
	text-align: center;
	border-bottom: 0;
	padding: 5%;
	margin-bottom: 0;
	line-height: 1.5;
}

.team-description p{
    font-size:18px;
    font-weight: 400;
}

.tabs-box{
	box-shadow: 0px 10px 30px rgba(66, 69, 97, 0.167799);
	border-radius: 8px;
}

.about-pg .row {
	--bs-gutter-x: 0;
}

.nav .nav-justified {
	align-items: stretch;
}

.tabs-box .nav-item .nav-link {
	position: relative;
	display: inline-block;
    text-decoration: none;
    text-align: center;
	width: 100%;
	height: 100%;
	padding-top: 32px;
	padding-bottom: 32px;
	font-size: 20px;
    color: #4A596B;
    font-weight: bold;
	padding-left: 16px;
    font-family: "HKGrotesk";
}

.about-pg h1 {
    padding-top: 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
}

/* green underline on tab click */
.nav-item .active{
	border-bottom: 4px
	solid #298B63;
}

.base-line{
    position:relative;
    margin:0;
    margin-top: -2px;
}

/* collapse to vertical tabs */
@media screen and (max-width: 767px) {
	.tabs-box .nav-item .nav-link {
		padding: 16px 0;
	}
	.nav-item .active {
		border-bottom: 4px
		solid #09E6F5; 	/* not sure if it's just me but why does it fade in*/
	}
	.about-team-desc{
		height: 35%;
	}
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
