.link-button {
    background-color: #156177;
    border-radius: 250px;
    padding: 14px;
    padding-left: 24px;
    padding-right: 24px; 
    color: white;
    font-size: 1.2em;
    text-decoration: none;
    display: inline-block
}

.link-button:hover {
    text-decoration: none;
    color: white; }
    
@media screen and (max-width: 453px) {
    .link-button {
        font-weight: bold;
        border-radius: 250px;
        padding: 3vw;
    }
}
