.day {
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    cursor: pointer;
    font-size: 12px;
    padding: 10px;
}

.day:hover {
  background-color: rgba(255, 196, 57, 0.2);
}

div.cal-side-panel {
  padding: 15px;
}

h1 {
    padding-top: 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
}

.cal-side-panel h3 {
  color: #4d4d4d;
  font-family: "HKGrotesk", Helvetica, sans-serif;
  font-size: 24px;
  font-weight: bold;
}

.cal-side-panel h5 {
  color: #5B5B5C;
  padding-top: 10px;
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: normal;
}

.subscribe-section {
    text-align: center;
    padding: 25px;
}

.events-tab-content {
    padding: 0px;
    margin: 0px;
    overflow: hidden;
}

.tabs-box {
	box-shadow: 0px 10px 30px rgba(66, 69, 97, 0.167799);
	border-radius: 8px;
}

.row {
	--bs-gutter-x: 0;
}

.nav .nav-justified {
	align-items: stretch;
}

.tabs-box .nav-item .nav-link {
	position: relative;
	display: inline-block;
    text-decoration: none;
    text-align: center;
	width: 100%;
	height: 100%;
	padding-top: 32px;
	padding-bottom: 32px;
	font-size: 20px;
    color: #4A596B;
    font-weight: bold;
	padding-left: 16px;
    font-family: "HKGrotesk";
}

/* green underline on tab click */
.nav-item .active {
	border-bottom: 4px
	solid #298B63;
}

.base-line {
    position:relative;
    margin:0;
    margin-top: -2px;
}

/* collapse to vertical tabs */
@media screen and (max-width: 767px) {
	.tabs-box .nav-item .nav-link {
		padding: 16px 0;
	}
	.nav-item .active {
		border-bottom: 4px
		solid #09E6F5; 	/* not sure if it's just me but why does it fade in*/
	}
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.cal-col {
  border-right: 2px solid #F8F7FC;
  padding: 0;
  overflow: hidden;
}

.cal-month-view-container {
  overflow: hidden;
}

.event-row {
  padding: 25px 60px;
  border-bottom: 1px solid #EEECF7;
}



.event-row h4 {
  font-family: "HKGrotesk";
  font-size: 22px;
}


.box-container {
  width: 100%;
  padding-bottom: 0;
}

.subscribe-section p {
  padding-bottom: 15px;
}

.event-location {
  text-align: center;
}

@media (min-width: 576px) {
  .event-location {
    padding: 0px 0px 0px 100px;
    text-align: left;
  }
}

.cal-side-panel .center {
  text-align: center;
  margin: 30px 0;
}

.event-location .link-button {
  display: inline-block;
  margin-top: 15px;
}