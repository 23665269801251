.company-row {
    height: auto;
    width: 100% !important;
}

/* .img-col {
    max-width: 10vmax !important;
    margin-right: 2vmax !important;
} */

.cf-map-image {
    width: 10vmax !important;
    height: auto !important;
    padding-right: 1vmax !important;
}

.company-name {
    width: 100%;
    font-size: 2vmax !important;
}

.cf-container,
.cf-row {
    width: 100% !important;
    overflow-x: hidden;
}

.cf-highlight-green {
    color: #6DC38E;
    font-weight: bold;
    max-width: fit-content !important;
}

.Standard,
.Gold,
.Silver {
    font-size: 1vmax;
    color: white;
    width: 6vmax;
    align-items: center;
    justify-content: center;
    height: 2.5vh;
    padding: 0.3vmax 0.5vmax;
    border-radius: 5px;
    display: inline-flex;
    margin-left: 1vmax !important;
    position: relative;
    bottom: 10%;
}

.Standard {
    background: rgba(205, 127, 50, 0.7);
}

.Gold {
    background-color: rgba(247, 181, 49, 0.7);
}

.Silver {
    background: rgba(91, 91, 92, 0.7);
}

.toggle-cf {
    min-width: 100%;
    font-size: 1.5vmax;
}

.toggle-btn {
    background: none;
    border: none;
    color: #8A8C8D;
    font-size: 1.5vmax;
    margin-top: 1vmax !important;
}

.target-info {
    font-size: 1.5vmax;
}

.cf-company-name {
    color: #00AAC9;
}

.side-menu-row-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
    background-color: white;
    border-bottom-left-radius: 15px;
}

@media screen and (max-width: 400px) {
    .cf-row {

    }
}