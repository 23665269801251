.featured-resources .slider-arrow,
.upcoming-events .slider-arrow {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    right: 1%;
    user-select: none;
    padding: 0;
    border: none;
    background: none;
    margin: 40px;
    align-self: center;
    height: fit-content;
  }

  .slider-arrow:disabled {
    color: rgb(213, 213, 213);
  }

  @media only screen and (max-width: 600px) {
    .featured-resources .slider-arrow,
    .upcoming-events .slider-arrow {
      margin: 0px;
    }
  }
  
