.cf-resources {
    width: 100%;
    padding: 2% 10% 1% 10%;
}

.cf-resources-container {
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    position: relative;
    display: flex;
}

.cf-resource-button {
    color: white;
    font-size: 20px;
    border-radius: 250px;
    text-decoration: none;
    padding: 14px;
    padding-right: 14px;
    padding-left: 14px;
    padding-left: 24px;
    padding-right: 24px;
    font-family: HKGrotesk;
    margin-top: 28px;
    margin-bottom: 15px;
}

.Y {
    background-color: #50B1B6;
}

.N {
    background-color: #EC7E6D;
}

.C {
    background-color: #F8CC66;
}

.N- {
    background-color: #6DC38E;
}

