.contact-bg {
    background: linear-gradient(
    to top, 
    #ffffff 0%, 
    #ffffff 50%, 
    rgb(242,242,249) 50%, 
    rgb(242,242,249)100%);
    /* padding: 2% 2% 2% 2%; */
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 1180px) {
    .contact-bg {
      display: block;
    }
		.left-side {
			background-color: rgb(242,242,249); 
		}
		.right-side {
			background: linear-gradient(
			to top, 
			#ffffff 0%, 
			#ffffff 80%, 
			rgb(242,242,249) 80%, 
			rgb(242,242,249)100%);
		}
}

@media only screen and (min-width: 1180px) and (max-width: 1400px) {
    .left-side {
        width: 70% !important;
    }
}

/* Get In Touch Section */

.left-side {
    width: 50%;
    padding: 1% 8% 6% 8%;
}

.left-side h1 {
    text-align: left;
    font-size: 35px;
    padding-bottom: 4%;
}

.left-side h3 {
    font-size: 20px;
}

.left-side p {
    font-size: 20px;
    padding-bottom: 4%;
}

a {
    color: rgb(90,102,119);
    font-weight: bold;
    text-decoration: none;
}

a:hover {
    color: rgb(1, 211, 207);
}

.info {
    padding-left: 10%;
    padding-top: 5%;
}

.details {
    padding-left: 7%;
    padding-top: 7%;
    padding-right:5%;
}

/* Form Section */

.right-side {
    width: 50%;
    padding: 5% 8% 4% 0%;
}

.form {
    background-color: white;
    padding: 4% 9% 7% 9%;
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgb(208, 208, 214);
}

.header {
    font-size: 25px;
    font-weight: normal;
    color: rgb(90,102,119);
    background-color: transparent;
    border: none;
		
}

.header:hover {
    color: rgb(1, 211, 207);
    background-color: transparent;
}

.header:focus {
    outline: none !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: rgb(1, 211, 207);
    background-color: transparent;
}

.teal {
    color: rgb(1, 211, 207);
    background-color: transparent;
}

.format-text {
    padding: 0 10% 1% 10%;
}

.field-formatting {
    background-color: rgb(242,242,249);
    margin: 2% 0% 1% 0;
    padding: 3% 4% 3% 4%;
    border: none;
    border-radius: 10px;
    font-size: 17px;
    outline: none;
}

div.contact-details {
    width:  100%;
}

.button {
    background-color: rgb(1, 211, 207);
    border: none;
    border-radius: 40px;
    font-size: 25px;
    padding: 1% 15% 1% 15%;
    margin: 4% 0 0 25%;
}

.button:hover {
    background-color: rgb(100, 224, 222);
}

.button:focus {
    outline: none;
}

@media screen and (max-width: 1175px) {
    .background {
        flex-direction: column;
        background: rgb(242,242,249)
    }

    /* Get In Touch Section */

    .left-side {
        width: 100%;
        padding: 5% 10% 3% 10%;
    }

    .left-side h1 {
        padding-bottom: 3%;
    }
    
    .left-side p {
        padding-bottom: 2%;
    }

    .info {
        padding-left: 10%;
        padding-top: 0%;
    }
    
    .details {
        padding-left: 7%;
        padding-top: 2%;
        padding-right:5%;
    }

    /* Form Section */

    .right-side {
        width: 100%;
        padding: 0% 10% 0% 10%;
    }

    .form {
        padding: 3% 8% 5% 8%;
        margin-bottom: 3%;
    }

}