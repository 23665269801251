button.close {
  background-color: transparent;
  border: none;
  font-size: 40px;
  line-height: 20px;
  color: #747474;
}

.modal-header {
  border-bottom: none;
  justify-content: end;
}

.modal-body {
  padding: 0px 50px 50px 50px;
}

.partner-name-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    margin: 0px;
    padding: 20px 5px 0px 5px;
    text-align: center;
    color: #000000;
}

.industry-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin: 0px;
    padding: 0px 5px 5px 5px;
    text-align: center;
    color: #5B5B5C; 
}

.career-fairs, .events {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.career-fairs-header, .events-header {
    display: inline-block;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 22px;
    line-height: 36px;
    margin: 0px;
    padding: 5px 30px 5px 0px;
    text-align: left;
    color: #000000; 
}

button.cf {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    border: none;
    color: white;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    width: 100px;
    border-radius: 8px;
    margin-right: 15px;
}

p.partner-description-text {
  padding-top: 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: justify;
  color: #363636;

}

.show-more-btn {
  font-size: 16px;
  color: #5B5B5C;
  background: #C4C4C4;
  border-radius: 8px;
  width: 34px;
  height: 20px;
  border: none;
  margin-left: 10px;
}

.dots {
  text-align: center;
  margin-top: -5px;
  font-size: 22px;
  letter-spacing: 2px;
}
