#booth-overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute; /* Sit on top of the page content */
	z-index: 999; /* Specify a stack order in case you're using a different order for other elements */
	cursor: pointer; /* Add a pointer on hover */
	background-color: #EC7E6D;
	color: white;
	border-radius: 20%;
	font-family: Helvetica;
	font-weight: bold;
	font-size: 20px;
}

@media only screen and (max-width: 900px) {
	#booth-overlay {
		border-width: 1px;
		font-size: 5px;
	}
}

