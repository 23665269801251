.career-fair-content .cf-list-row {
  text-align: center;
}
.career-fair-content .cf-list-box {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background-image: linear-gradient(#fffdeb, #fff1e3);
}

.career-fair-content .cf-list {
  width: 100%;
  margin-top: 5px;
}
.career-fair-content .cf-list .cf-img-container {
  height: 180px !important;
  margin-bottom: 50px;
  position: relative;
}

.career-fair-content .cf-list .cf-img {
  mix-blend-mode: multiply;
  width: 100%;
  /*max-height:130px;*/
  /*max-width:180px;*/
  height: auto;
  width: auto;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.gold-logo {
  max-height: 200px;
  max-width: 250px;
}

.silver-logo {
  max-height: 80px;
  max-width: 125px;
}

@media screen and (max-width: 991px) {
  .career-fair-content .cf-list .cf-img {
    max-width: 85%;
    max-height: 85%;
  }
}

.cf-img-container {
  position: relative;
  display: inline-block;
}

.cf-img-container .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 110%;
  left: 43%;
  margin-left: -60px;
}

.cf-img-container .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

.cf-img-container:hover .tooltiptext {
  visibility: visible;
  opacity: 0.95;
}
